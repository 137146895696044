import { fetchAuthSession } from "aws-amplify/auth";
import axios, { HttpStatusCode } from "axios";

class JobPosting {
  static post = async (jobDescription) => {
    try {
      const authTokenTemp =
        (await fetchAuthSession()).tokens?.idToken?.toString() ?? undefined;
      if (authTokenTemp) {
        const response = await axios.post(
          `https://0r2nxv1awa.execute-api.ap-southeast-1.amazonaws.com/v1/ground-truth-job-posting?isPotomac=true`,
          {
            jobDescription: jobDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${authTokenTemp}`,
            },
          }
        );
        if (response.status === HttpStatusCode.Ok) return response.data;
        else return null;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      throw error;
    }
  };
  static put = async (payload) => {
    try {
      payload.recModifiedDate = Date.now();
      const authTokenTemp =
        (await fetchAuthSession()).tokens?.idToken?.toString() ?? undefined;
      if (authTokenTemp) {
        const response = await axios.put(
          `https://0r2nxv1awa.execute-api.ap-southeast-1.amazonaws.com/v1/ground-truth-job-posting?isPotomac=true`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${authTokenTemp}`,
            },
          }
        );
        if (response.status === HttpStatusCode.Ok) return response.data;
        else return null;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      throw error;
    }
  };
  static get = async () => {
    try {
      const authTokenTemp =
        (await fetchAuthSession()).tokens?.idToken?.toString() ?? undefined;
      if (authTokenTemp) {
        const response = await axios.get(
          `https://0r2nxv1awa.execute-api.ap-southeast-1.amazonaws.com/v1/ground-truth-job-posting?isPotomac=true`,
          {
            headers: {
              Authorization: `Bearer ${authTokenTemp}`,
            },
          }
        );
        if (response.status === HttpStatusCode.Ok) return response.data;
        else return null;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      throw error;
    }
  };
  static delete = async (recCreatedDate) => {
    try {
      const authTokenTemp =
        (await fetchAuthSession()).tokens?.idToken?.toString() ?? undefined;
      if (authTokenTemp) {
        const response = await axios.delete(
          `https://0r2nxv1awa.execute-api.ap-southeast-1.amazonaws.com/v1/ground-truth-job-posting?isPotomac=true&recCreatedDate=${recCreatedDate}`,
          {
            headers: {
              Authorization: `Bearer ${authTokenTemp}`,
            },
          }
        );
        if (response.status === HttpStatusCode.Ok) return response.data;
        else return null;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      throw error;
    }
  };
  
}

export default class DataLabeling {
  static JobPosting = JobPosting;
}
