import { useNavigate } from 'react-router-dom';
import '../css/ConfirmationPage.css'
import { Button, Image } from 'react-bootstrap';
function ConfirmationPage() {
  const navigate = useNavigate();
  const mailtoLink = `mailto:${process.env.REACT_APP_MAIL_TO}?subject=${encodeURIComponent(process.env.REACT_APP_MAIL_REPORT_SUBJECT)}&body=${encodeURIComponent(process.env.REACT_APP_MAIL_REPORT_BODY)}`;
  return (
    <div className='confirm-container'>

      <Image className='thankyou-image' src={'/thank_you.jpg'} alt="Selected Image" />
      <h1>Thank You for Your Subscription!</h1>
      <p>Your payment has been confirmed.</p>
      <p>Enjoy your access to premium content.</p>
      {/* Add more details or actions as needed */}
      <Button onClick={() => { navigate("/") }} variant="primary">Return to Home</Button>
      <p className='mt-3'>For any questions, please contact us at <a href={mailtoLink}>{process.env.REACT_APP_MAIL_TO}</a></p>
    </div>
  )
}

export default ConfirmationPage;