import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enable: false,
  caseStudies: [],
  progress: {},
};

const caseStudySlice = createSlice({
  name: "caseStudies",
  initialState,
  reducers: {
    setEnableCaseStudy: (state, action) => {
      state.enable = action.payload;
    },
    setCaseStudies: (state, action) => {
      state.caseStudies = action.payload;
    },
    resetCaseStudies: (state) => {
      state.caseStudies = [];
    },
    setProgress: (state, action) => {
      if (Object.keys(state.progress).includes(action.payload.id)) {
        state.progress[action.payload.id].currentArticle =
          action.payload.currentArticle;
      } else {
        state.progress[action.payload.id] = {
          currentArticle: 1,
        };
      }
    },
    resetProgress: (state) => {
      state.progress = {};
    },
  },
});

export const { setEnableCaseStudy, setCaseStudies, resetCaseStudies, setProgress, resetProgress } =
  caseStudySlice.actions;
export default caseStudySlice.reducer;
