import React from "react";
import "./css/CaseStudyOutline.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setProgress } from "../states/features/caseStudy/caseStudySlice";

const CaseStudyOutline = ({ title, topics, onClose }) => {
  const params = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="case-study-name">{title}</h2>
        <div className="chapter-container">
          {topics.map((topic, index) => (
            <div
              className="chapter"
              style={{
                backgroundColor:
                  Number(params.chapter) === index + 1
                    ? "rgb(239, 239, 245)"
                    : "white",
              }}
            >
              <h3
                style={{ margin: 0 }}
                onClick={() => {
                  dispatch(
                    setProgress({ id: params.id, currentArticle: index + 1 })
                  );
                  onClose();
                }}
              >
                {index + 1}. {topic}
              </h3>
              {/* <div className="progress">
            <span>FREE</span>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: '10%' }}></div>
            </div>
          </div> */}
              {/* <ul>
            <li>Introduction to data driven decision making - 50 XP <span className="completed">&#10003;</span></li>
            <li>Exploring the database - 50 XP</li>
            <li>Exploring the table renting - 100 XP</li>
            <li>Filtering and ordering - 50 XP</li>
            <li>Working with dates - 100 XP</li>
            <li>Selecting movies - 100 XP</li>
            <li>Select from renting - 100 XP</li>
            <li>Aggregations - summarizing data - 50 XP</li>
            <li>Summarizing customer information - 100 XP</li>
            <li>Ratings of movie 25 - 100 XP</li>
            <li>Examining annual rentals - 100 XP</li>
          </ul> */}
            </div>
          ))}
        </div>
        <div className="button-container">
          <button
            className="close-button"
            onClick={() => navigate("/?tab=case-study")}
          >
            Back to Case Studies
          </button>
          {/* <button className="reset-button">Reset Course Progress</button> */}
        </div>
      </div>
    </div>
  );
};

export default CaseStudyOutline;
