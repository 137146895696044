
export async function transformDataTable(data) {

  // Initialize an empty object to store the transformed data
  const transformedData = {
    heatidx: [],
    precip: []
  };
  // Helper function to calculate mean and median
  const calculateStats = (values) => {
    const min = Math.min(...values);
    const max = Math.max(...values);
    const sum = values.reduce((acc, curr) => acc + curr, 0);
    const mean = sum / values.length;

    const sortedValues = values.sort((a, b) => a - b);
    const median = sortedValues.length % 2 === 0
      ? (sortedValues[sortedValues.length / 2 - 1] + sortedValues[sortedValues.length / 2]) / 2
      : sortedValues[Math.floor(sortedValues.length / 2)];

    return { min, max, mean, median };
  };

  // Iterate through the features to transform the data
  let cumSum = 0;
  let sumtemp
  let oldyYear = 0;
  data.features.forEach(feature => {
    const { time, heatidx, precip } = feature.properties;
    const date = time.split('T')[0];
    const hour = parseInt(time.split('T')[1].split(':')[0]);

    ['heatidx'].forEach(prop => {
      const propData = transformedData[prop];
      const itemIndex = propData.findIndex(item => item.date === date);
      const temps = { [prop]: [feature.properties[prop]] };

      if (itemIndex === -1) {
        propData.push({
          date,
          [`T${hour}`]: feature.properties[prop],
          temps
        });
      } else {
        propData[itemIndex][`T${hour}`] = feature.properties[prop];
        propData[itemIndex].temps[prop].push(feature.properties[prop]);
      }
    });
    if (!isNaN(precip)) {
      // const newDate = new Date(date);
      // const year = newDate.getFullYear();
      // if (year !== oldyYear) {
      //   cumSum = 0;
      //   oldyYear = year;
      // } else {
      //   cumSum += precip;
      // }
      sumtemp = precip;
      // // Repeat similar logic for other properties
      if (!transformedData.precip.some(item => item.date === date)) {
        transformedData.precip.push({
          date,
          [`T${hour}`]: precip,
          temps: { precip: [precip] },
          accum_rain_rate: cumSum,
          sum: precip
        });
      } else {
        const index = transformedData.precip.findIndex(item => item.date === date);
        transformedData.precip[index][`T${hour}`] = precip;
        transformedData.precip[index].sum += precip;
        // transformedData.precip[index].accum_rain_rate = cumSum;
        transformedData.precip[index].temps.precip.push(precip);
        sumtemp = transformedData.precip[index].sum;
      }
    }
  })
  let maxList = { "heatidx": [] }
  let sumList = { "precip": [] }
  // let cumSumList = { "accum_rain_rate": [] }
  for (const property in transformedData) {
    if (property === 'precip') {
      transformedData[property].forEach(item => {
        let date = new Date(item.date);
        if (item.sum !== null) {
          item.sum = parseFloat(item.sum.toFixed(2));
          // item.accum_rain_rate = parseFloat(item.accum_rain_rate.toFixed(2));
          sumList[property].push({ x: date, y: item.sum })
          // if (date.getFullYear() > 2019) {
          //   cumSumList["accum_rain_rate"].push({ x: date, y: item.accum_rain_rate })
          // }
        }
      });
    } else {
      transformedData[property].forEach(item => {
        const stats = calculateStats(item.temps[property]);
        item.max = stats.max;
        let date = new Date(item.date);
        maxList[property].push({ x: date, y: item.max })
      });
    }
  }




  const returnData = [{ name: "Rain Rate", type: "line", data: sumList.precip },{ name: "Heat Index", type: "line", data: maxList.heatidx }];
  return returnData;
};