import React from "react";
import JobCard from "./JobCard"; // Ensure you have a JobCard component

const JobContainer = ({ jobList, setTopic }) => {

  const handlePostJobClick = () => {
    setTopic("new-proposal");
  };

  return (
    <div>
      {jobList.length > 0 && (
        <div className="d-flex flex-column">
          {jobList.map((job) => (
            <JobCard key={`${job.userId}_${job.recCreatedDate}`} job={job} />
          ))}
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary" onClick={handlePostJobClick}>
              Post a Job
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobContainer;
