import Modal from 'react-bootstrap/Modal';

function ModelForHeatIndex(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          OSHA Heat Index Guidelines
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table style={{fontSize:'.85rem'}}>
          <thead>
            <tr>
              <th>Heat Index</th>
              <th>Risk Level</th>
              <th>Protective Measures</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{backgroundColor:'#FFFCD3'}}>
              <td>Less than 32.8°C</td>
              <td>Lower (Caution)</td>
              <td>Basic heat safety and planning</td>
            </tr>
            <tr style={{backgroundColor:'#FFEFD7'}}>
              <td>32.8 to 39.4°C</td>
              <td>Moderate</td>
              <td>
                <ul>
                  <li>Drink ~4 cups of water/hour</li>
                  <li>Take breaks as needed</li>
                </ul>
              </td>
            </tr>
            <tr style={{backgroundColor:'#FCE5D9'}}>
              <td>39.4 to 46.1°C</td>
              <td>High</td>
              <td>
                <ul>
                  <li>Drink water every 15-20 minutes</li>
                  <li>Take frequent breaks</li>
                  <li>Schedule heavy work tasks when the heat index is lower</li>
                </ul>
              </td>
            </tr>
            <tr style={{backgroundColor:'#F6D8D8'}}>
              <td>Greater than 46.1°C</td>
              <td>Very high to extreme</td>
              <td>
                <ul>
                  <li>Drink water frequently</li>
                  <li>Reschedule non-essential heavy work if possible</li>
                  <li>Alert workers to heat index for the day and identify precautions in place including who to call for medical help</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{fontSize:'.8rem'}}>
           This guidance is available online at :{" "}
          <a
            href="https://www.osha.gov/SLTC/heatillness/heat_index"
            target="_blank"
            rel="noreferrer"
          >
            https://www.osha.gov/SLTC/heatillness/heat_index
          </a>
        </p>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default ModelForHeatIndex;