
export function transformDataTable(data, predictData) {

  // Initialize an empty object to store the transformed data
  const transformedData = {
    heatidx: [],
    temp: [],
    temp2m: [],
    relhum: [],
    precip: [],
    tpw: []
  };
  // Helper function to calculate mean and median
  const calculateStats = (values) => {
    const min = Math.min(...values);
    const max = Math.max(...values);
    const sum = values.reduce((acc, curr) => acc + curr, 0);
    const mean = sum / values.length;

    const sortedValues = values.sort((a, b) => a - b);
    const median = sortedValues.length % 2 === 0
      ? (sortedValues[sortedValues.length / 2 - 1] + sortedValues[sortedValues.length / 2]) / 2
      : sortedValues[Math.floor(sortedValues.length / 2)];

    return { min, max, mean, median };
  };

  // Iterate through the features to transform the data
  let cumSum = 0;
  let oldyYear = 0;
  let last52WeeksDate = new Date();
  last52WeeksDate.setDate(last52WeeksDate.getDate() - 365);

  let minMaxLast52Weeks = {
    heatidx: [Infinity, -Infinity],
    temp: [Infinity, -Infinity],
    temp2m: [Infinity, -Infinity],
    relhum: [Infinity, -Infinity],
    tpw: [Infinity, -Infinity],
    precip: [Infinity, -Infinity]
  };
  data.features.forEach(feature => {
    const { time, heatidx, temp, temp2m, relhum, precip, tpw } = feature.properties;
    const date = time.split('T')[0];
    const hour = parseInt(time.split('T')[1].split(':')[0]);

    ['heatidx', 'temp2m', 'relhum', 'tpw', 'temp'].forEach(prop => {
      const propData = transformedData[prop];
      const itemIndex = propData.findIndex(item => item.date === date);
      const temps = { [prop]: [feature.properties[prop]] };

      if (itemIndex === -1) {
        propData.push({
          date,
          [`T${hour}`]: feature.properties[prop],
          temps
        });
      } else {
        propData[itemIndex][`T${hour}`] = feature.properties[prop];
        propData[itemIndex].temps[prop].push(feature.properties[prop]);
      }
    });
    if (!isNaN(precip)) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      if (year !== oldyYear) {
        cumSum = 0;
        oldyYear = year;
      } else {
        cumSum += precip;
      }
      let sumtemp = precip;
      // // Repeat similar logic for other properties
      if (!transformedData.precip.some(item => item.date === date)) {
        transformedData.precip.push({
          date,
          [`T${hour}`]: precip,
          temps: { precip: [precip] },
          accum_rain_rate: cumSum,
          sum: precip
        });
      } else {
        const index = transformedData.precip.findIndex(item => item.date === date);
        transformedData.precip[index][`T${hour}`] = precip;
        transformedData.precip[index].sum += precip;
        transformedData.precip[index].accum_rain_rate = cumSum;
        transformedData.precip[index].temps.precip.push(precip);
        sumtemp = transformedData.precip[index].sum;
      }

      if (newDate > last52WeeksDate) {
        ['heatidx', 'temp', 'temp2m', 'relhum', 'tpw', "precip"].forEach(prop => {
          minMaxLast52Weeks[prop][0] = Math.min(minMaxLast52Weeks[prop][0], feature.properties[prop]);
          minMaxLast52Weeks[prop][1] = Math.max(minMaxLast52Weeks[prop][1], feature.properties[prop]);
        });
      }
    }
  })
  let minMaxList = { "heatidx": [], "temp": [], "temp2m": [], "relhum": [], "tpw": [] }
  let maxList = { "heatidx": [], "temp": [], "temp2m": [], "relhum": [], "tpw": [] }
  let minList = { "heatidx": [], "temp": [], "temp2m": [], "relhum": [], "tpw": [] }
  let meanList = { "heatidx": [], "temp": [], "temp2m": [], "relhum": [], "tpw": [] }
  let medianList = { "heatidx": [], "temp": [], "temp2m": [], "relhum": [], "tpw": [] }
  let sumList = { "precip": [] }
  let cumSumList = { "accum_rain_rate": [] }
  // Calculate statistics for each property
  for (const property in transformedData) {
    if (property === 'precip') {
      transformedData[property].forEach(item => {
        let date = new Date(item.date);
        if (item.sum !== null) {
          item.sum = parseFloat(item.sum.toFixed(2));
          item.accum_rain_rate = parseFloat(item.accum_rain_rate.toFixed(2));
          sumList[property].push({ x: date, y: item.sum })
          if (date.getFullYear() > 2019) {
            cumSumList["accum_rain_rate"].push({ x: date, y: item.accum_rain_rate })
          }
        }
      });
    } else {
      transformedData[property].forEach(item => {
        const stats = calculateStats(item.temps[property]);
        item.min = stats.min;
        item.max = stats.max;
        item.median = parseFloat(stats.median.toFixed(2));
        item.mean = parseFloat(stats.mean.toFixed(2));
        let date = new Date(item.date);
        maxList[property].push({ x: date, y: item.max })
        minList[property].push({ x: date, y: item.min })
        meanList[property].push({ x: date, y: item.mean })
        medianList[property].push({ x: date, y: item.median })
        minMaxList[property].push({ x: date, y: [item.min, item.min, item.max, item.max] })
      });
    }
  }

  let minMaxListAndPredict = structuredClone(minMaxList);
  let maxListAndPredict = structuredClone(maxList);
  let minListAndPredict = structuredClone(minList);
  let meanListAndPredict = structuredClone(meanList);
  let medianListAndPredict = structuredClone(medianList);
  let sumListAndPredict = structuredClone(sumList);
  let cumSumListAndPredict = structuredClone(cumSumList);


  if (predictData) {

    predictData.features.forEach(feature => {
      const { time, heatidx, temp, temp2m, relhum, precip, tpw } = feature.properties;
      let date = new Date(time.split('T')[0]);
      // let date = new Date(item.date);
      // let date = time;
      maxListAndPredict.heatidx.push({ x: date, y: heatidx.max })
      minListAndPredict.heatidx.push({ x: date, y: heatidx.min })
      meanListAndPredict.heatidx.push({ x: date, y: heatidx.mean })
      medianListAndPredict.heatidx.push({ x: date, y: heatidx.median })
      minMaxListAndPredict.heatidx.push({ x: date, y: [heatidx.min, heatidx.min, heatidx.max, heatidx.max] })

      maxListAndPredict.temp.push({ x: date, y: temp.max })
      minListAndPredict.temp.push({ x: date, y: temp.min })
      meanListAndPredict.temp.push({ x: date, y: temp.mean })
      medianListAndPredict.temp.push({ x: date, y: temp.median })
      minMaxListAndPredict.temp.push({ x: date, y: [temp.min, temp.min, temp.max, temp.max] })

      maxListAndPredict.temp2m.push({ x: date, y: temp2m.max })
      minListAndPredict.temp2m.push({ x: date, y: temp2m.min })
      meanListAndPredict.temp2m.push({ x: date, y: temp2m.mean })
      medianListAndPredict.temp2m.push({ x: date, y: temp2m.median })
      minMaxListAndPredict.temp2m.push({ x: date, y: [temp2m.min, temp2m.min, temp2m.max, temp2m.max] })

      maxListAndPredict.relhum.push({ x: date, y: relhum.max })
      minListAndPredict.relhum.push({ x: date, y: relhum.min })
      meanListAndPredict.relhum.push({ x: date, y: relhum.mean })
      medianListAndPredict.relhum.push({ x: date, y: relhum.median })
      minMaxListAndPredict.relhum.push({ x: date, y: [relhum.min, relhum.min, relhum.max, relhum.max] })

      maxListAndPredict.tpw.push({ x: date, y: tpw.max })
      minListAndPredict.tpw.push({ x: date, y: tpw.min })
      meanListAndPredict.tpw.push({ x: date, y: tpw.mean })
      medianListAndPredict.tpw.push({ x: date, y: tpw.median })
      minMaxListAndPredict.tpw.push({ x: date, y: [tpw.min, tpw.min, tpw.max, tpw.max] })
    })
  }

  const lastestData = data.features[data.features.length - 1];
  for (const prop in minMaxLast52Weeks) {
    minMaxLast52Weeks[prop] = minMaxLast52Weeks[prop].map(val => val);
  }

  let summaryData = {
    "heatidx": lastestData.properties.heatidx,
    "temp": lastestData.properties.temp,
    "temp2m": lastestData.properties.temp2m,
    "relhum": lastestData.properties.relhum,
    "precip": transformedData["precip"][transformedData["precip"].length - 1].sum,
    "accum_rain_rate": parseFloat(cumSum.toFixed(2)),
    "tpw": lastestData.properties.tpw,
    "heatidx52Range": minMaxLast52Weeks["heatidx"].join(' - '),
    "temp52Range": minMaxLast52Weeks["temp"].join(' - '),
    "temp2m52Range": minMaxLast52Weeks["temp2m"].join(' - '),
    "relhum52Range": minMaxLast52Weeks["relhum"].join(' - '),
    "tpw52Range": minMaxLast52Weeks["tpw"].join(' - '),
    "precip52Range": minMaxLast52Weeks["precip"].join(' - ')
  }
  const returnData = { no_predict_data:{min_max: minMaxList, max: maxList, min: minList, mean: meanList, median: medianList, rain_rate: sumList, accum_rain_rate: cumSumList}, min_max: minMaxListAndPredict, max: maxListAndPredict, min: minListAndPredict, mean: meanListAndPredict, median: medianListAndPredict, rain_rate: sumListAndPredict, accum_rain_rate: cumSumListAndPredict, table_data: transformedData, summaryData: summaryData };
  return returnData;
};