import axios from 'axios';

const sendData = async (slipImageKey,plan,accessToken) => {
  try {
    const response = await axios.post(
      `https://p1l4gt4ufg.execute-api.ap-southeast-1.amazonaws.com/v2/web/payment`,
      {"slipImageKey":slipImageKey,"plan":plan},
      { headers:  {
        'Accept': 'application/json',
        'Authorization': accessToken,
      }}
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default sendData;
