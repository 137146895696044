import React from "react";
import CaseStudyBox from "./CaseStudyBox";
import "./css/CaseStudyList.css";

const CaseStudyList = ({ caseStudies }) => {
  return (
    <div>
      <h1>Case Studies</h1>
      <div className="container">
        {/* <div className="d-grid gap-3"> */}
        {caseStudies.map((caseStudy, index) => (
          <CaseStudyBox
            key={index}
            id={caseStudy.id}
            title={caseStudy.title}
            level={caseStudy.level}
            description={caseStudy.description}
            authorName={caseStudy.authorName}
            time={caseStudy.time}
          />
        ))}
      </div>
    </div>
  );
};

export default CaseStudyList;
