import { fetchAuthSession } from "aws-amplify/auth";
import axios, { HttpStatusCode } from "axios";

export default class CaseStudy {
  static get = async (caseStudy, article) => {
    try {
      const authTokenTemp =
        (await fetchAuthSession()).tokens?.idToken?.toString() ?? undefined;
      if (authTokenTemp) {
        const response = await axios.get(
          `${process.env.REACT_APP_POTOMAC_API_URL}/v2/web/case-study`,
          {
            params: {
              "case-study": caseStudy,
              article: article,
            },
            headers: {
              Authorization: authTokenTemp,
            },
          }
        );
        if (response.status === HttpStatusCode.Ok) return response.data;
        else return null;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching news:", error);
      throw error;
    }
  };
}
