import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import cropslist from '../components/crops.json';
import '../components/css/LoginPage.css';
import AWS from 'aws-sdk';
import ConfirmationPage from '../components/common/ConfirmationPage';
import PendingConfirmationPage from '../components/common/PendingConfirmationPage';

import makeAnimated from 'react-select/animated';
import { signIn, signOut, signUp, confirmSignIn, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Button, InputGroup, Container, Row, Col, FormControl, Form, Nav, NavDropdown, Dropdown, Image, Navbar, Modal } from 'react-bootstrap';

const animatedComponents = makeAnimated();

function LoginPage(props) {
  const navigate = useNavigate()
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(null);
  const [locationType, setLocationType] = useState('Country');

  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [country, setCountry] = useState('');
  const [session, setSession] = useState(null);
  const [otp, setOtp] = useState('');
  const [prefixNumber, setPrefixNumber] = useState('+66');
  const [number, setNumber] = useState('');
  const [isVerify, setIsVerify] = useState(false);
  const [status, setStatus] = useState('login'); // subscribe, login, signup, verify, pending
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
    verifyAuth();
  }, []);

  const verifyAuth = async () => {
    getCurrentUser()
      .then((user) => {
        if (user) {
          setUser(user);
        }
        setUser(user);
        setSession(null);
        if (user.username !== "guest_00001") {
          fetchAuthSession().then((session) => {
            let isSubscribeUser = session.tokens?.idToken?.payload['cognito:groups']?.includes('subscribe-user') || false;
            if (isSubscribeUser) {
              setStatus('confirm');
            } else {
              setStatus('pending');
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleSignOut = async () => {
    if (user) {
      signOut();
      setUser(null);
      setOtp('');
      setStatus('login');
      // setMessage(SIGNEDOUT);
    }
    //  else {
    //   setMessage(NOTSIGNIN);
    // }
  };
  const phoneNumberPattern = /^\d{9,10}$/;
  const handleSignIn = async () => {
    await signOut();
    setMessage('');
    let phone_number = number;
    if (number.startsWith('0')) {
      phone_number = number.slice(1);
      setNumber(phone_number);
    }
    if (phoneNumberPattern.test(phone_number) === false) {
      alert('Please enter valid phone number');
      setMessage('Please enter valid phone number phone number should be 9-10 digits');
      return;
    }
    signIn({ username: (prefixNumber + phone_number).replace('+', ''), options: { authFlowType: 'CUSTOM_WITHOUT_SRP' } })
      .then((result) => {
        setSession(result);
        // setIsVerify(true);
        handleShow();
      })
      .catch((e) => {
        if (e.message === 'User does not exist.') {
          navigate('/signup');
        } else {
          console.error(e);
        }
      });
  };

  const verifyOtp = async () => {
    setMessage('');
    if (otp === '') {
      setMessage('Please enter OTP');
      return;
    }
    confirmSignIn({
      challengeResponse: otp,
      session: session.toString(),
      username: (prefixNumber + number).replace('+', ''),
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      }
    })
      .then((user) => {
        if (user.isSignedIn) {
          setUser(user);
          setSession(null);
          setMessage('');

          fetchAuthSession().then((session) => {
            let isSubscribeUser = session.tokens?.idToken?.payload['cognito:groups']?.includes('subscribe-user') || false;
            if (isSubscribeUser) {
              setStatus('confirm');
              navigate('/');
            } else {
              navigate('/signup');
              // setStatus('pending');
              // let SlipImage = session.tokens.idToken.payload['custom:subscribe_slip_image'];
              // if (SlipImage) {
              //   setStatus('pending');
              //   handleClose();
              // } else {
              //   navigate('/signup');
              // }
            }
          });

        } else {
          setMessage('OTP is incorrect');
          // alert('OTP is incorrect');
          setOtp('');
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setOtp('');
        console.log(err);
      });
  };

  const handleUpload = async (selectedFile) => {
    if (!selectedFile) {
      // alert('Please select a file to upload.');
      return;
    }

    // Configure AWS SDK with your AWS credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3();
    const key = "private/slip/slip_" + Date.now() + "_" + selectedFile.name;
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: key,
      Body: selectedFile,
      // ACL: 'public-read' // Set ACL permissions (public-read allows public access)
    };

    try {
      // const data = await s3.upload(params).promise();
      await s3.upload(params).promise();
      // return data.Location;
      return key;
      // alert('File uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file. Please try again.');
      alert('Error uploading file. Please try again.');
    }
  };

  return (
    <>
      <Navbar expand="sm" className="bg-body-tertiary" sticky="top">
        <Container fluid>
          <Navbar.Brand onClick={() => { navigate("/") }} className="fontweight-700" style={{ cursor: "pointer" }}>The Potomac System</Navbar.Brand>
          {/* <Navbar.Brand href="/" className="fontweight-700 m-0 p0"><img src="logo.jpeg" height={100} alt="Logo" /></Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {/* <Nav.Link href="/">Home</Nav.Link> */}
            </Nav>

            {status !== 'login' && <div onClick={handleSignOut}>
              Sign Out
            </div>}
            {/* <i className="fa fa-sign-out-alt signout" aria-hidden="true" onClick={() => { handleSignOut() }}></i> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>
        <Row className="login-container">
          {status === 'login' ? (<div>
            <h3 className="already-have-account mb-5">Log In or create and account</h3>
            <h6 style={{ fontWeight: '800' }}>Phone Number</h6>
            <Container>
              <Row>
                <Col xs={2} className='px-0'> {/* Adjust the column width as needed */}
                  <InputGroup className='mb-3 px-0' >
                    <FormControl
                      placeholder='+XX'
                      value={prefixNumber}
                      onChange={(event) => setPrefixNumber(event.target.value)}
                      style={{ width: '100%' }}  // Ensures it takes full column width
                    />
                  </InputGroup>
                </Col>
                <Col xs={10}> {/* Adjust the column width as needed */}
                  <InputGroup className='mb-3 px-0'>
                    <FormControl
                      placeholder='Phone Number'
                      value={number}
                      onChange={(event) => setNumber(event.target.value)}
                      style={{ width: '100%' }}  // Ensures it takes full column width
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Container>
            {isVerify ? (
              <div>
                <h6 style={{ fontWeight: '800' }}>One-Time Password (OTP)</h6>
                <InputGroup className='mb-3'>
                  <FormControl
                    placeholder='Your OTP'
                    onChange={(event) => setOtp(event.target.value)}
                    value={otp}
                  />
                </InputGroup>
                <p className='text-danger'>{message}</p>
                <Button variant='outline-secondary'
                  style={{ width: '100%' }}
                  onClick={verifyOtp}>
                  Confirm
                </Button>
              </div>
            ) :
              (<>
                <p className='text-danger'>{message}</p>
                <Button variant='outline-secondary'
                  style={{ width: '100%' }}
                  onClick={handleSignIn}>
                  Get OTP
                </Button></>
              )
            }
          </div>
          ) : (
            status === 'pending' ? (
              <PendingConfirmationPage />
            ) : (
              <ConfirmationPage />
            )
          )}

        </Row>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body closeButton>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>One-Time Password (OTP)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your OTP"
                autoFocus
                onChange={(event) => setOtp(event.target.value)}
                value={otp}
              />
            </Form.Group>
          </Form>
          <p className='text-danger'>{message}</p>
          <Button variant="primary" style={{ width: "100%" }} onClick={verifyOtp}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LoginPage;