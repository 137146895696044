import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HistoryPage from './pages/HistoryPage';
import NewDetailPage from './pages/NewDetailPage';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ChartPage from './pages/ChartPage';
import Contacts from './components/contacts/Contacts';
import CaseStudyPage from './pages/CaseStudyPage';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-react/styles.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setEnableCaseStudy } from './states/features/caseStudy/caseStudySlice';
import { setEnableDataLabeling } from './states/features/dataLabeling/dataLabelingSlice';


Amplify.configure(awsExports);

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const searchParamsCaseStudy = new URLSearchParams(window.location.search);
        const caseStudy = searchParamsCaseStudy.get("case-study");
        if (caseStudy === "true") dispatch(setEnableCaseStudy(true));

        const searchParamsDataLabeling = new URLSearchParams(window.location.search);
        const dataLabeling = searchParamsDataLabeling.get("data-labeling");
        if (dataLabeling === "true") dispatch(setEnableDataLabeling(true));
    }, [dispatch]);

    return (
        <div>
            <Router>
            {/* <SiteNav isAuthenticated={isAuthenticated} updateAuthStatus={updateAuthStatus} /> */}
                <Routes>
                    <Route path='*' element={<SignUpPage/>} />
                    <Route path='/login' element={<LoginPage/>} />
                    <Route path='/signup' element={<SignUpPage/>} />
                    <Route path='/contacts' element={<Contacts/>} />
                    <Route path="/" element={<HistoryPage />} />
                    <Route path="/new-detail/:id" element={<NewDetailPage/>} />
                    <Route path="/chart/:token" element={<ChartPage/>} />
                    <Route path="/case-study/:id/:chapter" element={<CaseStudyPage/>} />
                </Routes>
            {/* <SiteFooter /> */}
            </Router>
        </div>
    );
};

export default App;
