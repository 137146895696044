const awsmobile = {
  authenticationFlowType: 'CUSTOM_AUTH',
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_IDENTITYPOOLID,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    authenticationFlowType: 'CUSTOM_AUTH',
    Cognito: {
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      authenticationFlowType: 'CUSTOM_AUTH',
      oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        scopes: ['openid', 'email', 'phone', 'profile', 'aws.cognito.signin.user.admin'],
        redirectSignIn: [process.env.REACT_APP_REDIRECT_URI],
        redirectSignOut: [process.env.REACT_APP_REDIRECT_URI],
        responseType: 'token',
      },
      username: 'false',
      email: 'true', // Optional
      phone: 'true', // Optional
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_AWS_BUCKET_NAME, //REQUIRED -  Amazon S3 bucket
      region: process.env.REACT_APP_AWS_REGION, //OPTIONAL -  Amazon service region
      isObjectLockEnabled: true //OPTIONAl - Object Lock parameter
    }
  }

};

export default awsmobile;