import React, { useState } from "react";
import "./css/CaseStudyBox.css"; // Import the CSS file for styling
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setProgress } from "../states/features/caseStudy/caseStudySlice";

const CaseStudyBox = ({ id, title, level, description, authorName, time }) => {
  const navigate = useNavigate();

  const progress = useSelector((state) => state.caseStudy.progress);
  const dispatch = useDispatch();

  const [navigating, setNavigating] = useState(false);

  const navigateToCaseStudyPage = async (event) => {
    if (!Object.keys(progress).includes(id)) dispatch(setProgress({ id: id }));

    if (event.target.tagName === "BUTTON") {
      if (!navigating) {
        setNavigating(true);
        navigate(`/case-study/${id}/${progress[id]?.currentArticle ?? 1}`);
      }
    } else {
      if (!navigating) {
        setNavigating(true);
        navigate(
          `/case-study/${id}/${progress[id]?.currentArticle ?? 1}/?outline=true`
        );
      }
    }
  };

  return (
    <div className="case-study-box" onClick={navigateToCaseStudyPage}>
      <div className="case-study-header">
        <h3>CASE STUDY</h3>
        <h2>{title}</h2>
      </div>
      <div className="case-study-level">
        <span className="beginner">{level}</span>
      </div>
      <p className="case-study-description">{description}</p>
      <div className="case-study-author">
        {/* <img src={authorImage} alt="Author" className="author-image" /> */}
        <div>
          <span className="author-name">{authorName}</span>
          {/* <span className="author-title">{authorTitle}</span> */}
        </div>
      </div>
      <div className="case-study-footer">
        {time ?? <span className="time">{time}</span>}
        <button className="start-button" onClick={navigateToCaseStudyPage}>
          Start
        </button>
      </div>
    </div>
  );
};

export default CaseStudyBox;
