import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  enable: false,
  jobList: [],
  loading: false,
};

const dataLabelingSlice = createSlice({
  name: "dataLabeling",
  initialState,
  reducers: {
    setEnableDataLabeling: (state, action) => {
      state.enable = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setJobList: (state, action) => {
      state.jobList = action.payload;
    },
    addJob: (state, action) => {
      state.jobList.push(action.payload);
    },
  },
});

export const { setEnableDataLabeling, setLoading, setJobList, addJob } =
  dataLabelingSlice.actions;
export default dataLabelingSlice.reducer;
