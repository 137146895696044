import axios from 'axios';


const getNews = async (authToken, category, country, startDate, endDate, keyword, LastEvaluatedKey) => {
  if(category === "All"){
    category = null;
  }
  try {
    const response = await axios.post(
      `https://p1l4gt4ufg.execute-api.ap-southeast-1.amazonaws.com/v2/web/news`,
      {
        category: category,
        country: country,
        startDate: startDate,
        endDate: endDate,
        keyword: keyword,
        LastEvaluatedKey: LastEvaluatedKey
      },
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': authToken,
        }
      }
    );
    // for(let i = 0; i < response.data.length; i++){
    //   response.data[i].readMore = false;
    // }
    return response.data;
  } catch (error) {
    // console.error('Error fetching news:', error);
    throw error;
  }
};

export default getNews;
