import React, { useState } from "react";
import { Card, Container, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setJobList, setLoading } from "../../states/features/dataLabeling/dataLabelingSlice";
import Services from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const JobCard = ({ job }) => {
  const [jobDescription, setJobDescription] = useState(job.jobDescription);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const k = searchParams.get("k");
  const m = searchParams.get("m");

  const managableJobStatus = ["pending", "on-going"];

  const editProposal = ({ cancel = false }) => {
    if (cancel) {
      setJobDescription(job.jobDescription);
      navigate({ search: "" });
      return;
    }
    navigate({ search: `?k=${job.userId}_${job.recCreatedDate}&m=edit` });
  }

  const saveEditedProposal = async () => {
    try {
      const payload = {
        ...job,
        jobDescription: jobDescription,
      }
      dispatch(setLoading(true));
      await Services.DataLabeling.JobPosting.put(payload);
      window.alert("Your proposal has been sent successfully!");
      try {
        const res = await Services.DataLabeling.JobPosting.get();
        if (res && res.Items) {
          dispatch(setJobList(res.Items));
        }
      } catch (_) {
        window.alert("Failed to retrieve job list. Please try again later.");
      }
    } catch (error) {
      console.error(error);
      window.alert("Failed to send proposal. Please try again later.");
    } finally {
      navigate({ search: "" });
      dispatch(setLoading(false));
    }
  }

  const isNeedEdit = () => {
    return k === `${job.userId}_${job.recCreatedDate}` && m === "edit";
  }

  const jobStatusBackgroundColor = () => {
    return job.status === 'pending' ? 'bg-warning' : job.status === 'on-going' ? 'bg-info' : job.status === 'complete' ? 'bg-success' : job.status === 'cancel' ? 'bg-danger' : 'secondary';
  }

  const showDeleteModal = () => {
    if (window.confirm("Are you sure you want to delete this job?")) {
      dispatch(setLoading(true));
      Services.DataLabeling.JobPosting.delete(job.recCreatedDate).then((deleteResult) => {
        Services.DataLabeling.JobPosting.get().then((getResult) => {
          window.alert("Job deleted successfully.");
          dispatch(setJobList(getResult.Items));
        }).catch((error) => {
          window.alert("Failed to retrieve job list. Please try again later.");
        }).finally(() => {
          dispatch(setLoading(false));
          navigate({ search: "" });
        })
      }).catch((error) => {
        window.alert("Failed to delete job. Please try again later.");
        dispatch(setLoading(false));
      })
    }
  }

  return (
    <div>
      <div>
        <div className="w-100" style={{ display: "inline" }}>
          <Container className="m-0 d-flex align-items-center justify-content-center">
            <Card className="w-100 m-0">
              <Card.Body>
                {isNeedEdit() ?
                  <div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                      <h5>Job Description</h5>
                      <FontAwesomeIcon type="button" icon={faTrash} className="fa-trash float-end" onClick={showDeleteModal} />
                    </div>
                    <Form>
                      <Form.Group controlId="jobDescription" className="mb-3">
                        <Form.Control
                          value={jobDescription}
                          as="textarea"
                          rows={10}
                          className="proposal-textarea"
                          placeholder="Enter your job description here"
                          onChange={(event) => setJobDescription(event.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </div> :
                  <p>
                    {job.jobDescription}
                  </p>}
                <div>
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <div className="d-flex flex-row align-items-center justify-content-center gap-1">
                      <strong>STATUS :</strong>
                      <div className={`${jobStatusBackgroundColor()} text-black text-capitalize rounded p-1`}>
                        {job.status}
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      {isNeedEdit() ?
                        <div className="d-flex align-items-center justify-content-center gap-2">
                          <button type="button" className="btn btn-outline-danger" color="secondary" onClick={() => editProposal({ cancel: true })}>
                            Cancel
                          </button>
                          <button type="button" className="btn btn-outline-success" color="primary" onClick={saveEditedProposal}>
                            Save
                          </button>
                        </div> :
                        <div>
                          {managableJobStatus.includes(job.status) && <button type="button" className="btn btn-primary" color="primary" onClick={editProposal}>
                            Manage
                          </button>}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
