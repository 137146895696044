import axios from "axios";
import CaseStudy from "./caseStudy";
import DataLabeling from "./dataLabeling";

axios.defaults.headers = {
  Accept: "application/json",
};

export default class Services {
  static CaseStudy = CaseStudy;
  static DataLabeling = DataLabeling;
}
