import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import citylist from '../components/citylist.json';
import cropslist from '../components/crops.json';
import '../components/css/LoginPage.css';
import AWS from 'aws-sdk';
import ConfirmationPage from '../components/common/ConfirmationPage';
import PendingConfirmationPage from '../components/common/PendingConfirmationPage';
import SendImageSlip from '../components/SendImageSlip';

import makeAnimated from 'react-select/animated';
import { signIn, signOut, signUp, confirmSignIn, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { Button, InputGroup, Container, Row, Col, FormControl, Form, Nav, NavDropdown, Dropdown, Image, Navbar, Modal } from 'react-bootstrap';

const animatedComponents = makeAnimated();

function LoginPage(props) {
  const navigate = useNavigate()
  const phoneNumberPattern = /^\d{9}$/;
  const [message, setMessage] = useState('');
  const [user, setUser] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [crop, setCrop] = useState([]);
  const [locationType, setLocationType] = useState('Country');
  const locationRadios = [
    { name: 'Country ', value: 'Country' },
    { name: 'Location', value: 'Location' }
  ];

  const [imageUrl, setImageUrl] = useState(null);
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [country, setCountry] = useState('');
  const [session, setSession] = useState(null);
  const [plan, setPlan] = useState(null);
  const [otp, setOtp] = useState('');
  const [prefixNumber, setPrefixNumber] = useState('+66');
  const [number, setNumber] = useState('');
  const [isAlreayUseFree, setIsAlreayUseFree] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [cropslistOption, setCropListOption] = useState([]);
  const [status, setStatus] = useState('subscription-plan'); // subscribe, login, signup, verify, pending

  const [isUser, setSetIsUser] = useState(false);
  const [authToken, setAuthToken] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirmFree, setShowConfirmFree] = useState(false);
  const [showConfirmEnterprise, setShowConfirmEnterprise] = useState(false);
  const [showThankEnterprise, setShowThankEnterprise] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true)
  const handleCloseConfirmFree = () => setShowConfirmFree(false);
  const handleShowConfirmFree = () => setShowConfirmFree(true);
  const handleCloseConfirmEnterprise = () => setShowConfirmEnterprise(false);
  const handleShowConfirmEnterprise = () => setShowConfirmEnterprise(true);
  const handleCloseThankEnterprise = () => setShowThankEnterprise(false);
  const handleShowThankEnterprise = () => setShowThankEnterprise(true);


  useEffect(() => {
    let cropList = [];
    cropslist.map((crop) => {
      cropList.push({ value: crop.english_name, label: crop.thai_name });
    });
    setCropListOption(cropList);
    verifyAuth();
  }, []);
  const getCurrentLocation = () => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        setLocationType('Location');
      });
    } catch (error) {
      console.log(error);
    }
  }
  const generateRandomPassword = (length = 12) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };
  const verifyAuth = async () => {
    getCurrentUser()
      .then((user) => {
        if (user) {
          setUser(user);
        }
        setUser(user);
        setSession(null);
        if (user.username !== "guest_00001") {
          fetchAuthSession({ forceRefresh: true }).then((session) => {
            let isSubscribeUser = session.tokens?.idToken?.payload['cognito:groups']?.includes('subscribe-user') || false;
            if (isSubscribeUser) {
              setStatus('confirm');
            } else {
              if (session.tokens?.idToken?.payload['custom:isalreadyUseFreePlan'] === "1") {
                setIsAlreayUseFree(true);
              }
              // setStatus('pending');
              let SlipImage = session.tokens.idToken.payload['custom:subscribe_slip_image'];
              let authTokenTemp = session.tokens?.idToken?.toString();
              setAuthToken(authTokenTemp);
              if (SlipImage) {
                setStatus('pending');
              } else {
                setSetIsUser(true)
              }
              //  else {
              //   setStatus('send-receipt');
              // }
            }
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const handleSignOut = async () => {
    if (user) {
      signOut();
      setUser(null);
      setOtp('');
      setStatus('subscription-plan');
      // setMessage(SIGNEDOUT);
    }
    //  else {
    //   setMessage(NOTSIGNIN);
    // }
  };
  const handleSignIn = async () => {
    await signOut();
    signIn({ username: (prefixNumber + number).replace('+', ''), options: { authFlowType: 'CUSTOM_WITHOUT_SRP' } })
      .then((result) => {
        setSession(result);
        // setIsVerify(true);
        handleShow();
      })
      .catch((e) => {
        if (e.message === 'User does not exist.') {
          setStatus("signup")
          getCurrentLocation();
        } else {
          console.error(e);
        }
      });
  };

  const selectPlan = (selectPlan) => {
    setPlan(selectPlan)
    if (isUser) {
      if (selectPlan === "free") {
        handleShowConfirmFree();
      } else {
        // setStatus('send-receipt');
        handleShowConfirmEnterprise();
      }
    } else {
      getCurrentLocation();
      setStatus('signup');

    }
  };
  const handleSignUp = async () => {
    let countrytemp = country.value;
    let latitudetemp = latitude;
    let longitudetemp = longitude;
    if (number.startsWith('0')) {
      number = number.slice(1);
      setNumber(number);
    }

    if (phoneNumberPattern.test(number) === false) {
      // alert('Please enter valid phone number');
      setMessage('Please enter valid phone number');
      return;
    }

    // Validate name
    if (!name) {
      // alert('Please enter your name');
      setMessage('Please enter your name');
      return;
    }

    // Validate email
    if (!email) {
      // alert('Please enter your email');
      setMessage('Please enter your email');
      return;
    }

    if (crop.length === 0) {
      // alert('Please select the crops you cultivate');
      setMessage('Please select the crops you cultivate');
      return;
    }
    if (locationType === 'Location') {
      if (!latitude || !longitude) {
        // alert('Please enter your location');
        setMessage('Please enter your location');
        return;
      }
      countrytemp = ''
      setCountry('');
    } else {
      if (!country) {
        // alert('Please select your country');
        setMessage('Please select your country');
        return;
      }
      latitudetemp = country.lat
      longitudetemp = country.lon
    }

    // const s3ImageUrl = await handleUpload(file);
    // if (!s3ImageUrl) {
    //   alert('Please upload your slip image');
    //   setMessage('Please upload your slip image');
    //   return;
    // }

    const result = await signUp({
      username: (prefixNumber + number).replace('+', ''),
      password: generateRandomPassword(),
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
        userAttributes: {
          phone_number: (prefixNumber + number),
          email: email,
          name,
          'custom:latitude': latitudetemp.toString(),
          'custom:longitude': longitudetemp.toString(),
          'custom:city': countrytemp,
          'custom:seleteLocationType': locationType,
          'custom:country': "THAILAND",
          'custom:crop': crop.map((crop) => crop.value).join(','),
          'custom:plan': plan,
          // "custom:plan": plan,
          // 'custom:subscribe_slip_image': s3ImageUrl
        }
      }
    }).then(() => {
      handleSignIn();
      setMessage('');
    }).catch((e) => {
      if (e.message === 'User already exists') {
        alert('User already exists');
        navigate('/login');
      } else {
        console.error(e);
      }
    });
    return result;
  };
  const verifyOtp = async () => {
    if (otp === '') {
      setMessage('Please enter OTP');
      return;
    }
    confirmSignIn({
      challengeResponse: otp,
      session: session.toString(),
      username: (prefixNumber + number).replace('+', ''),
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      }
    })
      .then((user) => {
        if (user.isSignedIn) {
          setUser(user);
          setSession(null);
          handleClose();
          if (plan === 'free') {
            setStatus('confirm');
            navigate('/');
          } else {
            // setStatus('send-receipt');
            setStatus('pending');
          }

          // fetchAuthSession().then((session) => {
          //   let isSubscribeUser = session.tokens?.idToken?.payload['cognito:groups']?.includes('subscribe-user') || false;
          //   if (isSubscribeUser) {
          //     setStatus('confirm');
          //     navigate('/');
          //   } else {
          //     setStatus('pending');
          //   }
          // });

        } else {
          setMessage('OTP is incorrect');
          // alert('OTP is incorrect');
          setOtp('');
        }
      })
      .catch((err) => {
        setMessage(err.message);
        setOtp('');
        console.log(err);
      });
  };


  const [file, setFile] = useState(null);

  const handleUpload = async () => {
    setMessage('');
    if (!file) {
      // alert('Please select a file to upload.');
      setMessage('Please select a file to upload.');
      return;
    }


    // Configure AWS SDK with your AWS credentials
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    });

    const s3 = new AWS.S3();
    const key = "private/slip/slip_" + Date.now() + "_" + file.name;
    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Key: key,
      Body: file,
      // ACL: 'public-read' // Set ACL permissions (public-read allows public access)
    };

    try {
      // const data = await s3.upload(params).promise();
      await s3.upload(params).promise();
      if (!authToken) {
        let session = await fetchAuthSession();
        let authTokenTemp = session.tokens?.idToken?.toString();
        setAuthToken(authTokenTemp);
        await SendImageSlip(key, plan, authTokenTemp);
        setStatus('pending');
      } else {
        await SendImageSlip(key, plan, authToken);
        setStatus('pending');
      }
      // navigate('/');
      // return data.Location;
      return key;
      // alert('File uploaded successfully.');
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Error uploading file. Please try again.');
      alert('Error uploading file. Please try again.');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    // if (selectedFile && selectedFile.type.startsWith('image/')) {
    setFile(selectedFile);
    setImageUrl(URL.createObjectURL(selectedFile));
    // } else {
    //   setMessage('Please select an image file.');
    //   alert('Please select an image file.');
    //   // Optionally clear the input field
    //   event.target.value = null;
    // }
  };
  const ConfirmFreePlanSelect = async () => {
    if (!authToken) {
      let session = await fetchAuthSession();
      let authTokenTemp = session.tokens?.idToken?.toString();
      setAuthToken(authTokenTemp);
      await SendImageSlip("", "free", authTokenTemp);
      navigate('/');
    } else {
      await SendImageSlip("", "free", authToken);
      navigate('/');
    }
  }
  
  const ConfirmEnterprisePlanSelect = async () => {
    if (!authToken) {
      let session = await fetchAuthSession();
      let authTokenTemp = session.tokens?.idToken?.toString();
      setAuthToken(authTokenTemp);
      await SendImageSlip("", "enterprise", authTokenTemp);
      // navigate('/');
      setStatus('pending');
    } else {
      await SendImageSlip("", "enterprise", authToken);
      // navigate('/');
      setStatus('pending');
      handleCloseConfirmEnterprise();
    }
  }


  return (
    <>
      <Navbar expand="sm" className="bg-body-tertiary" sticky="top">
        <Container fluid>
          <Navbar.Brand onClick={() => { navigate("/") }} className="fontweight-700" style={{ cursor: "pointer" }}>The Potomac System</Navbar.Brand>
          {/* <Navbar.Brand href="/" className="fontweight-700 m-0 p0"><img src="logo.jpeg" height={100} alt="Logo" /></Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {/* <Nav.Link href="/">Home</Nav.Link> */}
            </Nav>

            {/* <i className="fa fa-sign-out-alt signout" aria-hidden="true" onClick={() => { handleSignOut() }}></i> */}

            {(status === 'confirm' || status === 'pending') && <div onClick={handleSignOut}>
              Sign Out
            </div>}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div>

        <Row className="px-4 my-5">
          {/* {status === 'signup' && (
            <div className="back-button" onClick={() => { setStatus('login') }}>
              &larr; Back
            </div>)} */}

          {status === 'subscription-plan' ? (
            <div className="subscription-plan" >
              <h1>Discover Potomac Plan with our best offers.</h1>
              <div className="plans">
                <div className="plan">
                  <h2>Trial</h2>
                  <p style={{ marginBottom: '50px' }}>Free 30 days</p>
                  <ul>
                    <li className='mb-3'>Climate summary</li>
                    <li className='mb-3'>Visualize weather predictions</li>
                    <li className='mb-3'>Download historical data</li>
                    <li className='mb-3'>Compare weather parameters across different times and locations</li>
                  </ul>
                  <Button variant="primary" onClick={() => selectPlan("free")} disabled={isAlreayUseFree}>Select Plan</Button>

                  {isAlreayUseFree && <p className='text-danger' style={{ fontSize: "12px" }}>**You have already registered for this plan.</p>}
                </div>
                <div className="plan">
                  <h2>Enterprise</h2>
                  <p style={{ marginBottom: '50px' }}>Contact Sales</p>
                  <ul>
                    <li className='mb-3'>Climate summary</li>
                    <li className='mb-3'>Visualize weather predictions</li>
                    <li className='mb-3'>Download historical data</li>
                    <li className='mb-3'>Compare weather parameters across different times and locations</li>
                    <li className='mb-3'>News (more than 10 articles)</li>
                    <li className='mb-3'>Search News</li>
                  </ul>
                  <button onClick={() => selectPlan("contact-sale")}>Select Plan</button>
                </div>
              </div>

              <p className="already-have-account mt-4">If you already have an account? <a href='/login'>Log In</a></p>
            </div>
          ) : (
            status === 'signup' ? (
              <div>
                <div className="back-button" onClick={() => { setStatus('subscription-plan') }}>
                  &larr; Back
                </div>
                <div className="subscription-plan">
                  <h1>Account Set Up</h1>
                  <div className="plans">
                    {plan === 'free' ? (
                      <div className="plan">
                        <h2>Trial</h2>
                          <p style={{ marginBottom: '50px' }}>Free 30 days</p>
                          <ul>
                            <li className='mb-3'>Climate summary</li>
                            <li className='mb-3'>Visualize weather predictions</li>
                            <li className='mb-3'>Download historical data</li>
                            <li className='mb-3'>Compare weather parameters across different times and locations</li>
                          </ul>
                      </div>
                    ) : (
                      <div className="plan">
                        <h2>Enterprise</h2>
                            <p style={{ marginBottom: '50px' }}>Contact Sales</p>
                            <ul>
                              <li className='mb-3'>Climate summary</li>
                              <li className='mb-3'>Visualize weather predictions</li>
                              <li className='mb-3'>Download historical data</li>
                              <li className='mb-3'>Compare weather parameters across different times and locations</li>
                              <li className='mb-3'>News (more than 10 articles)</li>
                              <li className='mb-3'>Search News</li>
                            </ul>
                      </div>
                    )
                    }
                  </div>
                </div>
                <Container>
                  <Row>
                    <Col xs={3} className='px-0'> {/* Adjust the column width as needed */}
                      <InputGroup className='mb-3 px-0' >
                        <FormControl
                          placeholder='+XX'
                          value={prefixNumber}
                          onChange={(event) => setPrefixNumber(event.target.value)}
                          style={{ width: '100%' }}  // Ensures it takes full column width
                        />
                      </InputGroup>
                    </Col>
                    <Col xs={9}> {/* Adjust the column width as needed */}
                      <InputGroup className='mb-3 px-0'>
                        <FormControl
                          placeholder='Phone Number'
                          value={number}
                          onChange={(event) => setNumber(event.target.value)}
                          style={{ width: '100%' }}  // Ensures it takes full column width
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Container>
                <InputGroup className='mb-3'>
                  <FormControl
                    placeholder='Full Name'
                    onChange={(event) => setName(event.target.value)}
                  />
                </InputGroup>

                <Form.Group className="mb-3" >
                  <Form.Control type="text" minLength="8" placeholder="Email"
                    onChange={evt => setEmail(evt.target.value)} />
                </Form.Group>

                <Form className="d-flex ">
                  <Nav className="me-auto">
                    <NavDropdown
                      title={locationType}
                      id="input-group-dropdown-3"
                    >

                      {locationRadios.map((radio, idx) => (
                        <Dropdown.Item key={idx} href="#" onClick={() => setLocationType(radio.value)}>{radio.name}</Dropdown.Item>
                      ))}

                    </NavDropdown>
                  </Nav>
                  <InputGroup>
                    {locationType === 'Location' ? (
                      <>
                        <InputGroup.Text>Lat:</InputGroup.Text>
                        <Form.Control
                          value={latitude}
                          onChange={(e) => setLatitude(e.target.value)}
                          aria-label="Latitude"
                        />
                        <InputGroup.Text>Lon:</InputGroup.Text>
                        <Form.Control
                          value={longitude}
                          onChange={(e) => setLongitude(e.target.value)}
                          aria-label="Longitude"
                        />
                      </>
                    ) : (
                      <>
                        <Select
                          className="basic-single flex-grow-1 select-country" // Use flex-grow-1 class to make Select fill remaining space
                          classNamePrefix="select"
                          onChange={(e) => setCountry(e)}
                          options={citylist}
                          width={200}
                          isSearchable // Add this if you want a searchable Select
                        />
                      </>
                    )}
                  </InputGroup>
                </Form>
                <Form.Group controlId="formFile" className="mb-3 mt-3">
                  <Form.Label >The agricultural products you produce</Form.Label>
                  <CreatableSelect
                    className="my-0"
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    onChange={(e) => setCrop(e)}
                    isMulti
                    options={cropslistOption}
                    placeholder="Select the crops you cultivate"
                  />
                </Form.Group>

                {isVerify ? (
                  <div>
                    <InputGroup className='mb-3 mt-3'>
                      <FormControl
                        placeholder='Your OTP'
                        onChange={(event) => setOtp(event.target.value)}
                        value={otp}
                      />
                    </InputGroup>
                    <p className='text-danger'>{message}</p>
                    <Button variant='outline-secondary'
                      style={{ width: '100%' }}
                      onClick={verifyOtp}>
                      Confirm
                    </Button>
                  </div>
                ) : (
                  <div>
                    <p className='text-danger'>{message}</p>
                    <Button variant='outline-secondary'
                      style={{ width: '100%' }}
                      onClick={handleSignUp}>
                      Sign Up
                    </Button></div>)}
              </div>
            ) : (
              status === 'send-receipt' ? (
                <>

                  <div className="back-button" onClick={() => { setStatus('subscription-plan') }}>
                    &larr; Back
                  </div>
                  <div className="subscription-plan">
                    <h1>Payment</h1>
                    <div className="plans">
                      {plan === '1month' ? (
                        <div className="plan">
                          <h2>Monthly</h2>
                          <p style={{ marginBottom: '50px' }}>1990 Bath/Month</p>
                          <ul>
                            <li>Forecasting Graph</li>
                            <li className='mb-3'>Climate Summary</li>
                            <li className='mb-3'>News (more than 10 articles)</li>
                            <li className='mb-3'>Search News</li>
                            <li className='mb-3'>Download Historical Data</li>
                            <li className='mb-3'>Comparison Graph for Different Parameters, Locations, or Years</li>
                          </ul>
                        </div>
                      ) : (
                        <div className="plan">
                          <h2>Yearly</h2>
                          <p>19900 Bath/Month</p>
                          <p style={{ fontWeight: '600' }}>Save 16.7%</p>
                          <ul>
                            <li>Forecasting Graph</li>
                            <li className='mb-3'>Climate Summary</li>
                            <li className='mb-3'>News (more than 10 articles)</li>
                            <li className='mb-3'>Search News</li>
                            <li className='mb-3'>Download Historical Data</li>
                            <li className='mb-3'>Comparison Graph for Different Parameters, Locations, or Years</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="subscription-plan">
                    <div className="plans">
                      <Image src={'/qr_slip.jpg'} style={{ 'height': '400px' }} alt="Selected Image" />

                    </div>
                  </div>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Receipt of Payment</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                  </Form.Group>


                  {imageUrl && <Image src={imageUrl} style={{ 'width': '300px', 'marginBottom': '20px' }} alt="Selected Image" thumbnail />}
                  <p className='text-danger'>{message}</p>
                  <Button variant='outline-secondary'
                    style={{ width: '100%' }}
                    onClick={() => handleUpload()}>
                    Submit
                  </Button>
                  <Button variant='secondary'
                    style={{ width: '100%', marginTop: '10px' }}
                    onClick={() => navigate('/')}>
                    Back To Home
                  </Button>
                </>
              ) : (
                status === 'pending' ? (
                  <PendingConfirmationPage />
                ) : (
                  <ConfirmationPage />
                ))
            )
          )
          }
        </Row>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton>
        </Modal.Header> */}
        <Modal.Body closeButton>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>One-Time Password (OTP)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your OTP"
                autoFocus
                onChange={(event) => setOtp(event.target.value)}
                value={otp}
              />
            </Form.Group>
          </Form>
          <p className='text-danger'>{message}</p>
          <Button variant="primary" style={{ width: "100%" }} onClick={verifyOtp}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmFree} onHide={handleCloseConfirmFree} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body closeButton>
          <h2 style={{ textAlign: "center" }}>Would you like to confirm your registration for the Trial Plan?</h2>

          <Button variant="primary" style={{ width: "100%", marginTop: "40px" }} onClick={ConfirmFreePlanSelect}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmEnterprise} onHide={handleCloseConfirmEnterprise} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body closeButton>

          <h2 style={{ textAlign: "center" }}>Would you like to confirm your registration for the Enterprise Plan?</h2>

          <Button variant="primary" style={{ width: "100%", marginTop: "40px" }} onClick={ConfirmEnterprisePlanSelect}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>

      <Modal show={showThankEnterprise} onHide={handleCloseThankEnterprise} centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body closeButton>

          <h2 style={{ textAlign: "center" }}>We will contact you shortly.</h2>

          <Button variant="primary" style={{ width: "100%", marginTop: "40px" }} onClick={handleCloseThankEnterprise}>
            Confirm
          </Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LoginPage;