// store.js
import { configureStore } from "@reduxjs/toolkit";
import caseStudySlice from "./features/caseStudy/caseStudySlice";
import dataLabelingSlice from "./features/dataLabeling/dataLabelingSlice";

const store = configureStore({
  reducer: {
    caseStudy: caseStudySlice,
    dataLabeling: dataLabelingSlice,
    // Add other reducers here
  },
});

export default store;
