import '../css/ContentLocker.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

const ContentLocker = ({  unlockUrl }) => {
    const navigate = useNavigate();


  const unlockContent = async () => {
    // await signOut()

    navigate('/signup');
  };

  return (
    <div className="content-locker">
        <div className="locked-overlay">
          <div className="locked-message">
            <div className="inline-content">
              <img src="/padlock.png" alt="Lock Icon" />
              <h4>Subscribe To Unlock</h4>
              <img src="/padlock.png" alt="Lock Icon" />
            </div>
            <p>This content is available for our subscribers. Please subscribe to our Website to unlock the content.</p>
            <button onClick={unlockContent}>Click to Subscribe</button>
          </div>
        </div>
    </div>
  );
};

export default ContentLocker;
