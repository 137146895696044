import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic as style } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./css/MarkdownRenderer.css"; // Import CSS file for styling

const CodeBlock = ({ language, value }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="code-block">
      <CopyToClipboard text={value} onCopy={handleCopy}>
        <button className="copy-button" aria-label="Copy code">
          {copied ? (
            <>
              <FontAwesomeIcon icon={faCheck} color="lightgreen" /> Copied!
            </>
          ) : (
            <FontAwesomeIcon icon={faCopy} />
          )}
        </button>
      </CopyToClipboard>
      <SyntaxHighlighter style={style} language={language} PreTag="div">
        {value}
      </SyntaxHighlighter>
    </div>
  );
};

const MarkdownRenderer = ({ markdown }) => {
  return (
    <ReactMarkdown
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <CodeBlock
              language={match[1]}
              value={String(children).replace(/\n$/, "")}
            />
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {markdown}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
