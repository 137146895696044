import axios from 'axios';

const getNewsDetail = async (id, authToken) => {
  try {
    const response = await axios.get(
      `https://p1l4gt4ufg.execute-api.ap-southeast-1.amazonaws.com/v2/web/news/detail?id=${id}`,
      { headers: { Authorization: authToken, Accept: 'application/json' } }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching news:', error);
    throw error;
  }
};

export default getNewsDetail;
