import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import getNewsDetail from '../components/GetNewsDetail';
import citylist from '../components/citylist.json';
import Select from 'react-select';
import { Dropdown, Button, Form, InputGroup, Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { signOut, fetchAuthSession } from 'aws-amplify/auth';

const NewsDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [news, setNews] = useState(null);
  const [newsLoading, setNewsLoading] = useState(true);
  const [selectLocationTypeTemp, setSelectLocationTypeTemp] = useState("Country")
  const [latitudeTemp, setLatitudeTemp] = useState(13.75);
  const [longitudeTemp, setLongitudeTemp] = useState(100.5);
  const locationRadios = [
    { name: 'Country ', value: 'Country' },
    { name: 'Location', value: 'Location' }
  ];
  const [selectedCountryTemp, setSelectedCountryTemp] = useState({ value: 'Bangkok Metropolis', label: 'Bangkok Metropolis', lon: 100.5, lat: 13.75 });
  const options = {
    weekday: 'short', // Short name of the day (e.g., Fri)
    month: 'long', // Full name of the month (e.g., May)
    day: 'numeric', // Day of the month (e.g., 3)
    year: 'numeric', // Full year (e.g., 2024)
    hour: 'numeric', // Hour in 12-hour format (e.g., 3)
    minute: 'numeric', // Minute (e.g., 17)
    second: 'numeric', // Second (e.g., 0)
    timeZoneName: 'short', // Time zone name (e.g., GMT+7)
  };

  const handleLogout = async () => {
    try {
      console.log('Logout');
      await signOut()

      navigate('/login');
    } catch (err) { console.log(err) }
  }


  const handleLatitudeChange = (event) => {
    setLatitudeTemp(event.target.value);
  };

  const handleLongitudeChange = (event) => {
    setLongitudeTemp(event.target.value);
  };


  const handleLocationSearch = () => {
    if (selectLocationTypeTemp === 'Country') {
      navigate(`/?lat=${selectedCountryTemp.lat}&lon=${selectedCountryTemp.lon}&city=${selectedCountryTemp.value}`);
    } else {
      navigate(`/?lat=${latitudeTemp}&lon=${longitudeTemp}`);
    }
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountryTemp(selectedOption);
    setLatitudeTemp(selectedOption.lat);
    setLongitudeTemp(selectedOption.lon);
  };

  function timeAgo(date) {
    const currentDate = new Date();
    const diff = currentDate - date;
    const minutes = Math.floor(diff / 60000);

    if (minutes < 1) {
      return 'just now';
    } else if (minutes === 1) {
      return '1 minute ago';
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (minutes < 120) {
      return '1 hour ago';
    } else if (minutes < 1440) {
      return `${Math.floor(minutes / 60)} hours ago`;
    } else if (minutes < 2880) {
      return '1 day ago';
    } else if (minutes < 20160) { // 14 * 24 * 60
      return '1 week ago';
    } else if (minutes < 525600) { // 365 * 24 * 60
      return `${Math.floor(minutes / 10080)} weeks ago`;
    } else {
      return `${Math.floor(minutes / 525600)} years ago`;
    }
  }

  function datetimePattern(date) {
    const currentDate = new Date(parseInt(date));
    return currentDate.toLocaleString('en-US', options);
  }
  async function getDetail() {
    const authTokenTemp = (await fetchAuthSession()).tokens?.idToken?.toString();
    getNewsDetail(id, authTokenTemp).then((news) => {
      setNewsLoading(false);
      setNews(news);
    }).catch((error) => {
      console.log("error", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    });
  }


  useEffect(() => {
    getDetail();
  }, []);


  return (
    <>
      <Navbar expand="sm" className="bg-body-tertiary" sticky="top">
        <Container fluid>
          <Navbar.Brand onClick={() => { navigate("/") }} className="fontweight-700" style={{ cursor: "pointer" }}>The Potomac System</Navbar.Brand>
          {/* <Navbar.Brand href="/" className="fontweight-700 m-0 p0"><img src="logo.jpeg" height={100} alt="Logo" /></Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {/* <Nav.Link href="/">Home</Nav.Link> */}
            </Nav>

            <Form className="d-flex search-bar">
              <Nav className="me-auto">
                <NavDropdown
                  title={selectLocationTypeTemp}
                  id="input-group-dropdown-3"
                >

                  {locationRadios.map((radio, idx) => (
                    <Dropdown.Item key={idx} href="#" onClick={() => setSelectLocationTypeTemp(radio.value)}>{radio.name}</Dropdown.Item>
                  ))}

                </NavDropdown>
              </Nav>
              <InputGroup>
                {selectLocationTypeTemp === 'Location' ? (
                  <>
                    <InputGroup.Text>Lat:</InputGroup.Text>
                    <Form.Control
                      value={latitudeTemp}
                      onChange={handleLatitudeChange}
                      aria-label="Latitude"
                    />
                    <InputGroup.Text>Lon:</InputGroup.Text>
                    <Form.Control
                      value={longitudeTemp}
                      onChange={handleLongitudeChange}
                      aria-label="Longitude"
                    />
                    <Button variant="secondary" onClick={() => handleLocationSearch()}><i className="pi pi-search" style={{ fontWeight: '900' }}></i></Button>
                  </>
                ) : (
                  <>
                    <Select
                      className="basic-single flex-grow-1 select-country" // Use flex-grow-1 class to make Select fill remaining space
                      classNamePrefix="select"
                      value={selectedCountryTemp}
                      onChange={handleCountryChange}
                      options={citylist}
                      width={200}
                      isSearchable // Add this if you want a searchable Select
                    />
                    <Button className="search-button" variant="secondary" onClick={() => handleLocationSearch()}><i className="pi pi-search" style={{ fontWeight: '900' }}></i></Button>
                  </>
                )}
              </InputGroup>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="app mx-auto new-detail-page">
        {newsLoading && <div>Loading...</div>}
        {news && news.error && <div>{news.error}</div>}
        {news && !news.error &&
          <div className="news-detail-item col-md-12">
            <div className="news-date">{news.publisher_name} : {timeAgo(news.publication_date)}</div>
            <div className="news-detail-title" >{news.title}</div>
            <div className="news-detail-author-container" >
              <div className="news-detail-author">{news.source_name}</div>
              <div className="news-detail-datetime">{datetimePattern(news.modification_datetime)}</div>
            </div>
            <div className="news-detail-content">{news.body}</div>
          </div>
        }
      </div>
    </>
  );
};

export default NewsDetailPage;
