import React, { useState, useEffect, useMemo } from "react";
import JobContainer from "../components/data_labeling/JobContainer";
import { Container, Card, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingModal from "../components/LoadingModal";
import { useDispatch, useSelector } from "react-redux";
import "../components/css/DataLabelingPage.css"; // Import a custom CSS file
import { setJobList, setLoading } from "../states/features/dataLabeling/dataLabelingSlice";
import Services from "../services";
import groupBy from "../utils/groupBy";

export const DataLabelingPage = () => {
    const [topic, setTopic] = useState("job-posting");
    const [proposal, setProposal] = useState("");
    const [jobStatus, setJobStatus] = useState("all");
    const [allJob, setAllJob] = useState({});

    const jobList = useSelector((state) => state.dataLabeling.jobList);
    const loading = useSelector((state) => state.dataLabeling.loading);
    const dispatch = useDispatch();

    const topicList = useMemo(() => ["job-posting", "new-proposal"], []);
    const jobStatusList = ["all", "pending", "on-going", "completed", "canceled"];

    useEffect(() => {
        setTopic(topicList[0]);
    }, [topicList]);

    useEffect(() => {
        setAllJob(groupBy(jobList, 'status'));
    }, [jobList])

    const sendProposal = async (event) => {
        event.preventDefault();

        try {
            dispatch(setLoading(true));
            await Services.DataLabeling.JobPosting.post(proposal);
            window.alert("Your proposal has been sent successfully!");
            const res = await Services.DataLabeling.JobPosting.get();
            if (res && res.Items) {
                dispatch(setJobList(res.Items));
                setProposal("");
                setTopic("job-posting");
            }
        } catch (error) {
            console.error(error);
            window.alert("Failed to send proposal. Please try again later.");
        } finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <>
            {loading ? (
                <LoadingModal />
            ) : (
                <div className="data-labeling-page">
                    <div className="header-tab">
                        {topicList.map((item) => 
                            <div
                                key={item}
                                className={`title ${topic === item ? "active" : ""} text-capitalize`}
                                onClick={() => setTopic(item)}
                            >
                                {item.replace(/-/g, " ")}
                            </div>
                        )}
                    </div>
                    {topic === "job-posting" && (
                        <div>
                            <div className="header-tab-2 gap-2" style={{ width: 'fit-content' }}>
                                {jobStatusList.map((status) =>
                                    <button
                                        type="button"
                                        key={status}
                                        className={`btn title ${jobStatus === status ? "btn-primary" : "active"} text-capitalize`}
                                        onClick={() => setJobStatus(status)}
                                    >
                                        {status}
                                    </button>
                                )}
                            </div>
                            <JobContainer jobList={jobStatus === "all" ? jobList : (allJob[jobStatus] ?? [])} setTopic={setTopic} />
                        </div>
                    )}
                    {topic === "new-proposal" && (
                        <Container fluid>
                            <Card className="text-center">
                                <Card.Header as="h5">Job Description</Card.Header>
                                <Card.Body>
                                    <Form className="gap-2" onSubmit={sendProposal}>
                                        <Form.Group controlId="jobProposal">
                                            <Form.Control
                                                value={proposal}
                                                as="textarea"
                                                rows={10}
                                                className="proposal-textarea"
                                                placeholder="Enter your job proposal here"
                                                onChange={(event) => setProposal(event.target.value)}
                                            />
                                        </Form.Group>
                                        <Button className="m-2" variant="primary" type="submit">
                                            Send a Job Proposal
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Container>
                    )}
                </div>
            )}
        </>
    );
};

export default DataLabelingPage;
